.font_h1 {
    font-size: 2rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: lighter;
}

.font_h2 {
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 600;
}

.font_h3 {
    font-size: 1.17rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h4 {
    font-size: 1.25rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h5 {
    font-size: 0.83rem;
    font-family: 'Noto Sans', sans-serif;
    font-weight: normal;
}

.font_h6 {
    font-size: 0.625rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_body {
    font-size: 1.6rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_subtitle {
    font-size: 1.4rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_button {
    font-size: 0.75rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_caption {
    font-size: 1.2rem;
    font-family: 'Noto Sans', sans-serif;
}

.font_overline {
    font-size: 1rem;
    font-family: 'Noto Sans', sans-serif;
}

.color_primary {
    background: #7f1084;
    color: #fff;
}

.color_secondary {
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
}

.color_button {
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
}

.color_title {
    background: transparent;
    color: #000000;
}

.page_color {
    background: #000;
    color: #fff;
}

.color_active {
    background: transparent;
    color: #7f1084;
}

.contact_front_d {
    background: linear-gradient(to left, #00a100, #5bc700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
}

.contact_back_d .list-group {
    /* border: 1px solid #7f1084; */
}

.contact_back_d .contact_back_d_header {
    background: linear-gradient(to top, #00a100, #5bc700);
    color: #fff;
    border-radius: 5px 0 0 0;
}

.contact_back_d .contact_back_d_item {
    background: #fff1e6;
    color: #000;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.contact_back_d .contact_back_d_item a{
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    background: #fff;
    border-radius: 5px;
    padding: .25rem;
}

.contact_back_d .contact_back_d_item .contact_back_content span {
    color: #808080;
}

.contact_back_d.card>.list-group:last-child {
    border-radius: 0 0 0 5px;
}

.announcement_body {
    color: #000;
}

.announcement_header {
    /* border-bottom: 1px solid #7f1084; */
    border-bottom: 0;
    background: #fff;
}

.announcement_header img {
    /* filter: drop-shadow(1px 2px 0px black); */
}

.announcement_header_title {
    color: #ff6a00;
}

.announcement_btnClose {
    background: linear-gradient(to top, #00a100, #5bc700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border-top: 0px solid;
    border-bottom: 0px solid;
    border-left: 0px solid;
    border-right: 0px solid;
}

.header_topBar {
    background: #fff;
    color: #000;
    /* filter: drop-shadow(0 2px 3px rgb(0, 0, 0, .1)); */
    /* box-shadow: 1px 0px 3px 0px rgba(255, 255, 255, .3); */
    border-bottom: 1px solid #ff6a00;
}

.header_menu {
    color: rgba(255, 255, 255, .5);
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    box-shadow: 0px 0px 8px 0px rgb(0 0 0 / .9);
}

.header_menu .header_menu_page .header_menu_content.active {
    background: transparent;
    color: #fff;
    /* border-bottom: 3px solid #7f1084; */
}

.banking_dropdown a li,
.claim_dropdown li {
    background: transparent;
    color: #666666;
}

.banking_dropdown a.active li,
.claim_dropdown a.active li {
    background: transparent;
    color: #ff6a00;
}

.header_btnLogin {
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    /* border-top: 1px solid #7f1084;
    border-bottom: 1px solid #7f1084;
    border-left: 1px solid #7f1084;
    border-right: 1px solid #7f1084; */
}

.header_btnRegister {
    background:linear-gradient(to top, #00a100, #5bc700);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnLogout {
    background: linear-gradient(to top, #00a100, #5bc700);
    color: #ffffff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnRestore {
    background: linear-gradient(to top, #00a100, #5bc700);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
}

.header_btnBanking {
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
    border: 0;
    border-radius: 5px 5px 5px 5px;
}

.header_btnPromoClaim {
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border: 0
}

.header_topBar+.header_banking_info {
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 60%);
}

.header_sideBar_topBar {
    background: #fff;
    color: #000;
    filter: drop-shadow(0px 0px 3px rgba(0 0, 0, .8));
}

.navbar-toggler {
    background: transparent;
    color: #000;
}

.header_sideBar .header_sideBar_body {
    background: #fff1e6;
    color: #fff;
}

.header_sideBar_menu .header_menu_content,
.header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p:last-child {
    background: transparent;
    color: #666666;
    border-bottom: 1px solid #666666;
    margin: 0 .85rem;
}

.header_sideBar_menu .header_menu_content.active {
    background: transparent;
    color: #ff6a00;
    border-bottom: 1px solid #666666;
}

.header_banking_info .header_banking_menu_list {
    color: #999;
}

.header_banking_info .header_banking_menu_list .active {
    color: #ff6a00;
    background: transparent;
    border-radius: 5px;
}

.header_topBar_wrap .header_banking_info .header_balance {
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
}

.header_banking_info .header_balance_icon {
    color: #000;
}

#promoClaimModal label {
    color: #ff6a00;
}

#promoClaimModal .filterTab .filterTab_selection.active {
    background: #fff;
    color: #000;
}

.footer {
    background: #fff;
    color: #ff6a00;
    /* box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%); */
    /* border-top: 1px solid #7f1084; */
}

.footer_menu_wrap {
    border-bottom: 1px dashed #000;
}

.footer_payment_method .footer_info_img li:first-of-type img {
    /* filter: brightness(0) invert(1); */
}

.btnCancel {
    background: #e6e6e6;
    color: #000;
    border: unset;
    border-radius: 5px 5px 5px 5px;
}

.modal .confirm_button {
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.modal .cancel_button {
    background: #e6e6e6;
    color: #000;
    border: unset;
    border-radius: .375rem;
    padding: .375rem .75rem;
}

.tab_pills_color.nav-pills .nav-link:hover {
    color: #fff;
}

.footer_help a:hover,
.header_banking_menu_list a:hover,
.header_menu_right .header_menu_page:hover,
.walletBar_selection:hover,
.banking_dropdown a li:hover,
.header_menu_page .header_text:hover,
.claim_dropdown li:hover {
    color: #ff6a00;
}

.header_menu_page .header_text:hover {
    color: #fff;
}

.header_sideBar_menu .header_menu_page .header_text:hover {
    color:#ff6a00;
}
.banking_dropdown a li:hover,
.claim_dropdown li:hover {
    background: transparent;
    color: #ff6a00;
}

.scrollToTopButton {
    background: linear-gradient(to top, #00a100, #5bc700);
    color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    padding: .375rem .75rem;
}

.swiper .swiper-pagination-bullet {
    background: #ff6a00;
}

.alertMessage_content svg {
    color: #ff6a00;
}

/* .form_required_input,
.register_error_msg,
.forgotPassword_error_msg,
.forgotUsername_error_msg,
.error_msg,
.form_required,
.displayQR_row label,
.input_error_msg {
    color: #fff;
} */

.login_form_reminder_title,
.register_form_reminder_title,
.forgotPassword_form_reminder_title,
.forgotUsername_form_reminder_title {
    color: red;
    font-weight: bold;
}

.forgot_content .forgot_content_username,
.forgot_content .forgot_content_password {
    color: red;
}

.btnCancelPromotion {
    background: linear-gradient(to top, #00a100, #5bc700);
    color: #fff;
}

.btnAfterVerify {
    background: #e6e6e6;
    color: #000;
    border: 0;
}

.announcement_header_title span {
    margin-bottom: .25rem;
}

.section_title {
    color: #000;
}

.header_topBar_wrap .header_bottom_row .header_balance.coupon_balance {
    background: #cccccc;
    color: #000;
    outline: unset;
    border-radius: 5px 5px 5px 5px;
}

#gameWalletModal .game_wallet_dropdown table tbody tr {
    color: #000;
    background: #e6e6e6;
}

#promoClaimModal .filterTab {
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
    border-radius: 10px;
}

#promoClaimModal .filterTab .filterTab_selection {
    border: 0;
    border-radius: 10px;
}

.section_details {
    color: #4d4d4d;
}

.card_feedback .feedback_rating_wrap .feedback_rating img {
    margin-right: .25rem;
}

.header_banking_info .header_banking_menu_list span.active {
    background: transparent;
}

/* .header_logo img {
    height: 4rem;
  }
  .header_d .header_logo img {
    height: 5rem;
  } */

.header_sideBar.offcanvas {
    background: #fff1e6;
}

.gameWallet_accordion .accordion-button {
    background: #ffe8d9;
    color: #000;
}



.banking_dropdown a li .header_WalletIcon{
    width: 35px;
    height: 35px;
    margin-right: .75rem;
    margin-left: .75rem;
}

.btn_banking .banking_dropdown, .btn_claim .claim_dropdown{
    background: #fff;
}

.gameWallet_accordion .gameWallet_accordion_icon{
    background: linear-gradient(to top, #ff3b00, #ff6a00);
    color: #fff;
}


.announcement_header::before {
    background: #ff6a00;
  }

  .header_point span:nth-child(1){
    color: #ff6a00;
  }


  .btn_gamewallet .game_wallet_dropdown{
    background: #fff;
    color: #000;
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .3));
  }


  
  .header_logo img{
    height: 4rem;
  }

  .header_d .header_logo img{
    height: 5.5rem;

  }

  .btn_banking .banking_dropdown, .btn_claim .claim_dropdown {
   
}

.banking_dropdown li, .claim_dropdown li{
    padding: 0;
    font-size: .9rem;
}

.btn_banking .banking_dropdown, .btn_claim .claim_dropdown{
    width: 13rem;
    background: #fff;
    color: #000;
    -webkit-transform: translate(-50%,2%);
    transform: translate(-50%,2%);
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, .3));
}